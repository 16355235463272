'use client'

import parse from 'html-react-parser'
import Link from 'next/link'
import { useCallback, useContext, useEffect, useState } from 'react'
import Update from '@/contexts/UpdateContext'
import { fetchHub } from '@/lib/hub/fetchHub'
import { Hub } from '@/lib/types'

type HubDetailsProps = {
  hub: Hub
}

export default function HubDetails({ hub: hubData }: HubDetailsProps) {
  const { date } = useContext(Update.Context)
  const [initialLoad, setInitialLoad] = useState(true)
  const [hub, setHub] = useState<Hub>(hubData || ({} as Hub))
  const [isCommunityPicks, setIsCommunityPicks] = useState<boolean>(false)
  const formUrl = 'https://forms.gle/SBYR7GDbhAUfncB29'
  const editorialPublickKey = '9pT3uFkTXUqonHAiMyw9Y9Rvx1G3ShQnuGHzgwesiBb5'

  const handleFetchHub = useCallback(async () => {
    if (initialLoad) {
      setInitialLoad(false)
      checkIfCommunityPicks(hubData)

      return
    }

    try {
      const { hub: fetchedHubData } = await fetchHub(hub.handle)

      if (fetchedHubData) {
        checkIfCommunityPicks(fetchedHubData)
        setHub(fetchedHubData)
      }
    } catch (error) {
      console.error('Error fetching hub:', error)
    }
  }, [date])

  const checkIfCommunityPicks = (hub: Hub) => {
    if (
      hub.authority === editorialPublickKey &&
      hub.handle.includes('community-picks-')
    ) {
      setIsCommunityPicks(true)
    }
  }

  useEffect(() => {
    handleFetchHub()
  }, [handleFetchHub])

  const description = hub.data?.description
  const descriptionHtml = hub.data?.descriptionHtml || ''

  const sanitizedDescription = descriptionHtml
    .replace(/^"/, '')
    .replace(/"$/, '')

  const parsedHtml = parse(sanitizedDescription)

  if (
    (descriptionHtml === '' && description === '') ||
    description === 'undefined'
  )
    return <p className="body-1 text-grey-45">This hub has no description.</p>

  if (isCommunityPicks)
    return (
      <div className="body-1 whitespace-pre-wrap py-8">
        <p className="body-1 text-blac mt-16 max-w-full md:mt-20 md:max-w-[600px]">
          1 Favorite = 1 Vote. Every week, artists submit releases, listeners
          vote, and the release with the most votes gets{' '}
          <Link className="underline" href="/hubs/community-picks">
            editorial coverage
          </Link>{' '}
          on Nina. Artists submit{' '}
          {formUrl && (
            <Link
              href={formUrl}
              className="underline"
              target="_blank"
              passHref
              rel="noopener noreferrer"
            >
              here
            </Link>
          )}
          .
        </p>
      </div>
    )

  return (
    <div className="h-auto w-full md:w-1/2">
      {descriptionHtml && !isCommunityPicks && (
        <div className="body-1 whitespace-pre-wrap py-8">{parsedHtml}</div>
      )}
      {description && !descriptionHtml && !isCommunityPicks && (
        <div className="body-1 whitespace-pre-wrap py-8">{description}</div>
      )}
    </div>
  )
}
